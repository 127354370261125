import Error from 'next/error';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { type ReactElement } from 'react';
import { env } from '@replay/env-generator';
import { type Locale } from '@replay/i18n/types/locale';

const LOCALE_DEFAULT: Locale = 'fr';

function getErrorUrl(locale: Locale): string {
    return `https://www.arte.tv/error/current/${locale}/404.html`;
}

const Page404 = (): ReactElement => {
    const router = useRouter();
    const locale: Locale = router.locale ? (router.locale as Locale) : LOCALE_DEFAULT;
    const errorUrl = getErrorUrl(locale);

    if (env.NODE_ENV === 'development') {
        return <Error statusCode={404} />;
    }
    return (
        <Head>
            <meta httpEquiv="Refresh" content={`0; URL=${errorUrl}`} />
        </Head>
    );
};
export default Page404;
